import posthog from 'posthog-js';

// Check that we're in the browser before initializing PostHog
if (typeof window !== 'undefined') {
   posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
      // Enable debug mode in development
      loaded: (posthog) => {
         if (process.env.NODE_ENV === 'development') posthog.debug();
      },
      // Disable capturing by default
      capture_pageview: false,
      // If you want to use PostHog's EU servers
      // api_host: 'https://eu.posthog.com',
   });
}

export default posthog;
